<template>
  <div class="mrow fwnr">
    <div class="wrapper">
      <div class="mtit1">
        <h2 class="cn">服务内容</h2>
        <div class="en">Service Content</div>
        <div class="line"></div>
      </div>
      <div class="fwnr-list">
        <ul>
          <li>
            <img class="bgimg" src="../../../assets/images/icon76-bg.png" />
            <div class="hd">
              <img class="icon" src="../../../assets/images/icon76.png" />
              <h3>代理服务</h3>
            </div>
            <div class="bd">
              为企业提供证照注册、财税代理等服务高新企业认定、软件著作权登记、企业技术中心认定、专精特新中小企业认定、河北省百人计划等科技、发改、工信和人社部门设立的项目。
            </div>
          </li>
          <li>
            <img class="bgimg" src="../../../assets/images/icon77-bg.png" />
            <div class="hd">
              <img class="icon" src="../../../assets/images/icon77.png" />
              <h3>咨询服务</h3>
            </div>
            <div class="bd">
              重点围绕“专精特新”中小企业、高新技术企业、企业技术研发机构等认定申报政策进行解读，帮助企业获得政策红利和资质。
            </div>
          </li>
          <li>
            <img class="bgimg" src="../../../assets/images/icon78-bg.png" />
            <div class="hd">
              <img class="icon" src="../../../assets/images/icon78.png" />
              <h3>人才引进</h3>
            </div>
            <div class="bd">
              人才绿卡、高端人才科研经费补贴和安家补贴、企业引进高端人才奖励资助。
            </div>
          </li>
          <li>
            <img class="bgimg" src="../../../assets/images/icon79-bg.png" />
            <div class="hd">
              <img class="icon" src="../../../assets/images/icon79.png" />
              <h3>技术创新服务</h3>
            </div>
            <div class="bd">
              引导企业重视和加强技术创新能力建设，帮助专精特新中小企业建立、提升研发机构；鼓励企业与高校、科研院所加强产学研合作，加大研发投入和技术改造投资力度。
            </div>
          </li>
          <li>
            <img class="bgimg" src="../../../assets/images/icon80-bg.png" />
            <div class="hd">
              <img class="icon" src="../../../assets/images/icon80.png" />
              <h3>企业融资服务</h3>
            </div>
            <div class="bd">
              协调金融机构创新金融产品，建立重点企业融资项目库，组织有融资需求的企业进行一对一、一对多的融资对接等。引导企业多层次上市培育，根据企业发展不同阶段，由专业团队提供企业改制上市等个性化辅导、培训和服务。
            </div>
          </li>
          <li>
            <img class="bgimg" src="../../../assets/images/icon81-bg.png" />
            <div class="hd">
              <img class="icon" src="../../../assets/images/icon81.png" />
              <h3>人才培养服务</h3>
            </div>
            <div class="bd">
              组织企业参加知名高校管理学院、商学院等高层管理者培训；组织优势资源深入企业开展技能培训、财税培训、融资培训等；开展专项能力提升培训班等。
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toRouter(path) {
      newRouter(path);
    },
  },
};
</script>
<style scoped lang="scss">
.gjrcc-list li:nth-child(1) .bgimg {
  background-image: url(../../../assets/images/gjrcbg01.jpg);
}
.gjrcc-list li:nth-of-type(2) .bgimg {
  background-image: url(../../../assets/images/gjrcbg03.jpg);
}
.gjrcc-list li:nth-of-type(3) .bgimg {
  background-image: url(../../../assets/images/gjrcbg03.jpg);
}
</style>
