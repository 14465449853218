<template>
  <div class="hczs mrow">
    <div class="rcchj wrapper">
      <div class="mtit1">
        <h2 class="cn">人才城环境</h2>
        <div class="en">Environmental photos</div>
        <div class="line"></div>
      </div>

      <div class="rcchj-list kjzs-list">
        <div class="swiper-container">
          <ul class="swiper-wrapper">
            <li class="swiper-slide"
                v-for="item in pictureList"
                :key="item.id">
              <a>
                <img :src="item.pic" />
                <h3 class="tit">{{ item.title }}</h3>
              </a>
            </li>
          </ul>
        </div>
        <div class="swiper-button-prev kjzs-prev"></div>
        <div class="swiper-button-next kjzs-next"></div>
      </div>
    </div>
    <div class="hcqyzs">
      <div class="mtit1">
        <h2 class="cn">企业展示</h2>
        <div class="en">Enterprise Show</div>
        <div class="line"></div>
      </div>
      <div class="hcqyzs-list mimgs-tabbd">
        <div class="swiper-container">
          <ul class="swiper-wrapper">
            <li class="swiper-slide"
                v-for="item in list"
                :key="item.id">
              <div class="mimgs-box">
                <a @click="toDetail('article', item)">
                  <div class="img"><img :src="item.cover" /></div>
                  <h3>{{ item.title }}</h3>
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div class="mimgs-btn">
          <div class="swiper-button-prev mimgs-prev"></div>
          <div class="swiper-button-next mimgs-next"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
import Swiper from "@/libs/js/swiper.min";
export default {
  data () {
    return {
      pictureList: [],
      list: [],
    };
  },
  mounted () {
    //企业展示
    this.getTabArticleList();
    this.getPictureList();
  },
  methods: {
    toRouter (param) {
      newRouter(param);
    },
    toDetail (path, data) {
      newRouter("/" + path, {
        id: data.id,
      });
    },
    swiperInit () {
      new Swiper(".mimgs-tabbd .swiper-container", {
        slidesPerView: 2,
        loop: true,
        autoplayDisableOnInteraction: false,
        autoplay: 4000,
        centeredSlides: true,
        nextButton: ".mimgs-next",
        prevButton: ".mimgs-prev",
        breakpoints: {
          1000: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      });
    },
    swiperStart () {
      new Swiper(".kjzs-list .swiper-container", {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: true,
        autoplayDisableOnInteraction: false,
        autoplay: 4000,
        nextButton: ".kjzs-next",
        prevButton: ".kjzs-prev",
        breakpoints: {
          1000: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      });
    },
    //企业展示
    getTabArticleList () {
      this.api
        .getTabArticleList({ pageNo: 1, pageSize: 50, type: 10 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.list = records;
          setTimeout(() => {
            this.swiperInit();
          }, 300);
        });
    },
    getPictureList () {
      this.api
        .getPictureList({ pageNo: 1, pageSize: 50, type: 6 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.pictureList = records;
          setTimeout(() => {
            this.swiperStart();
          }, 300);
        });
    },
  },
};
</script>
<style scoped lang="scss"></style>
