<template>
  <div class="hchd mrow">
    <div class="wrapper">
      <div class="mtit1">
        <h2 class="cn">活动</h2>
        <div class="en">Event</div>
        <div class="line"></div>
      </div>
      <div class="hchd-list">
        <ul class="mnewslist mnewslist2">
          <li v-for="item in list" :key="item.id">
            <a @click="toDetail('article', item)">
              <div class="img"><img :src="item.cover" /></div>
              <div class="txts">
                <div class="left">
                  <h3 class="tit">
                    {{ item.title }}
                  </h3>
                  <div class="info" v-html="item.articleAbstract">
                    <!-- {{ item.articleAbstract }} -->
                  </div>
                  <div class="time">{{ item.releaseTime }}</div>
                </div>
                <div class="right">
                  <span class="mbtn ful small orange disabledGray"
                   @click="(e)=>toRouter(e,'apply',{cn:'参加活动',en:'Participate in the activity',activeId:item.id})">{{item.state}}</span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getTabArticleList();
  },
  methods: {
    toDetail(path, data) {
      newRouter("/" + path, {
        id: data.id,
      });
    },
    toRouter (e,routerName, item) {
      e.stopPropagation()
      let query = {
        ...item
      };

      this.newRouter('/' + routerName, query)
    },
    //企业展示
    getTabArticleList() {
      this.api
        .getTabArticleList({ pageNo: 1, pageSize: 50, type: 11 })
        .then((res) => {
          console.log(res,'rrr')
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.list = records;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.disabledGray{
  background:#ccc;
      border: 1px solid #ccc;
      color:#000;
}
</style>
