<template>
  <div class="el_policy">
    <banner />
    <introduce />
    <results />
    <sidebar />
    <activity />
  </div>
</template>

<script>
import banner from "./banner.vue";
import introduce from "./introduce.vue";
import results from "./results.vue";
import sidebar from "./sidebar.vue";
import activity from "./activity.vue";

export default {
  data() {
    return {
      msg: "el_policy",
      list1: [],
    };
  },
  components: {
    banner,
    introduce,
    results,
    sidebar,
    activity,
  },
  created() {},
  methods: {
    toRouter() {
      console.log(8889999);
      this.$router.push({ name: "article" });
    },
  },
};
</script>
<style scoped lang="scss"></style>
