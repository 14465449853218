<template>
  <div class="mbanner">
    <div class="wrapper">
      <div class="mbanner-hd">
        <div class="cn"><h2>海创空间</h2></div>
        <div class="en">Innovation space</div>
      </div>
      <div class="mbanner-bd mbanner-bd1">
        海创空间成立于2016年6月18日，占地面积约3000平方米，<br />
        集创业项目孵化、高端人才引进、科技成果转化、资源共享交流为一体，<br />
        打造全球科技成果转化中心和海内外人才创新创业基地。<br />
        集聚资源，整合统效。海创空间集聚丰富的创新创业资源，<br />
        依托完善的创新创业孵化服务体系，构建全链条创新创业生态系统，<br />
        以科技服务为导向，聚焦产业，面向政府、双创基地和科技企业，提供全方位一站式服务。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.mbanner {
  background-image: url(../../../assets/images/mbanner14.jpg);
}
</style>
