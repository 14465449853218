<template>
  <div class="fhcg mrow">
    <div class="wrapper">
      <div class="mtit1">
        <h2 class="cn">孵化成果</h2>
        <div class="en">Incubation results</div>
        <div class="line"></div>
      </div>
      <div class="fhcg-list">
        <ul>
          <li v-for="item in list"
              :key="item.id"
              :style="{ backgroundImage: 'url(' + item.labelBackgroundMap + ')' }">
            <div class="hd">
              <img :src="item.labelIcon" /> <span class="hdNum">{{ item.labelValue }}</span>
            </div>
            <div class="bd"
                 v-if="max1201">
              {{ item.labelContent }}
            </div>
            <div class="bd"
                 v-else>
              {{ item.labelContent.replace(/\s*/g,"") }}
            </div>
          </li>
        </ul>
      </div>
      <div class="fhcg-list1">
        <div class="swiper-container">
          <ul class="swiper-wrapper">
            <li class="swiper-slide"
                v-for="item in pictureList"
                :key="item.id">
              <img :src="item.pic" />
              <h3>{{ item.title }}</h3>
            </li>
          </ul>
        </div>
        <div class="swiper-button-prev fhcg-prev"></div>
        <div class="swiper-button-next fhcg-next"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "@/libs/js/swiper.min";
import { newRouter } from "@/libs/comm";
export default {
  data () {
    return {
      list: [],
      pictureList: [],
      max1201: false,
    };
  },
  mounted () {
    //孵化成果
    this.getAchievementsList();
    this.getPictureList();

    window.onresize = () => {
      this.sizeChange();
    }
    this.sizeChange();
  },
  methods: {
    sizeChange () {
      if (document.body.offsetWidth > 1201) {
        this.max1201 = true;
      } else {
        this.max1201 = false;
      }
    },
    toRouter () {
      newRouter("apply");
    },
    swiperInit () {
      new Swiper(".fhcg-list1 .swiper-container", {
        slidesPerView: 4,
        spaceBetween: 30,
        loop: true,
        autoplayDisableOnInteraction: false,
        autoplay: 4000,
        nextButton: ".fhcg-next",
        prevButton: ".fhcg-prev",
        breakpoints: {
          1000: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      });
    },
    //孵化成果
    getAchievementsList () {
      this.api
        .getAchievementsList({ pageNo: 1, pageSize: 50, labelType: 2 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.list = records;
        });
    },
    getPictureList () {
      this.api
        .getPictureList({ pageNo: 1, pageSize: 50, type: 5 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.pictureList = records;
          setTimeout(() => {
            this.swiperInit();
          }, 300);
        });
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 1201px) {
  .hd {
    position: relative;
  }
  .hdNum {
    position: absolute;
    left: 45%;
  }
  .bd {
    text-align: center;
  }
}
</style>
